import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from '@app/modules/shared/models/address';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { Ticket } from '@app/modules/shared/models/ticket';
import { Voucher } from '@app/modules/shared/models/voucher';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { SubscriptionDetails } from '@app/modules/subscriptions/models/subscription.interface';
import { CacheManager } from '@app/utils/cache-manager';
import { environment as env } from '@env/environment';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  AnonymizeResponse,
  Attribute,
  Contract,
  Customer,
  Document,
  SendResetPasswordEmailResponse,
  SetCustomerAttributeResponse,
  UpdateInfoResponse,
} from '../models/customer';
import { CustomersFiltersService } from './filters.service';

export interface $$Ticket {
  id: string;
  ticket_id: string;
  user_id: string;
  order_identifier?: string;
  order_id?: string;
  order_date?: string;
  product_id: string;
  start_date?: string;
  end_date?: string;
  label: string;
  status: string;
  network_id: string;
  dematerialized: string;
  category_id?: string;
}

export interface $$Profile {
  customer_id: string;
  isParent: boolean;
  firstname: string;
  lastname: string;
  status: string;
  picture: string;
  active: boolean;
  anonymized_at?: any;
}

export interface $$Customer {
  customer_id: string;
  network_ids: number[];
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  address: {
    streetNumber: string;
    route: string;
    city: string;
    zipCode: string;
    country: string;
  };
  picture: string;
  date: string;
  birthday: string;
  active: string;
  anonymized_at?: any;
  universal: boolean;
  provider: string;
  provider_uid: string;
}

export interface $$GlobalAttribute {
  id: string;
  key: string;
  network_id: string;
  label: string;
  type: 'ENUM' | string;
  values: string[];
}

@Injectable()
export class CustomersService {
  private cache = new CacheManager('customers');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private globalFilters: FilterService,
    private customerFilters: CustomersFiltersService
  ) {}

  public getCustomers(): Observable<Customer[]> {
    let version = this.cache.getVersion();

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<GenericResponse<Customer[]>>(env.config.feedRoot + `Customer/list.json`, {
          params: {
            v: version,
            ...this.globalFilters.filtersWithID,
            ...this.customerFilters.getCleanValue(),
          },
        })
      ),
      tap(this.authService.checkLoggedIn),
      map((payload) => {
        // handle empty response as empty array
        if (!payload) return [];

        return payload.response.customers;
      })
    );
  }

  public $$toggleCustomerStatus(customerId: string): Promise<number> {
    const http$ = this.http
      .post<GenericResponse<number>>(env.config.feedRoot + 'Customer/desOrActivateCustomer', {
        id: customerId,
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => {
          return response.status;
        })
      );

    return firstValueFrom(http$);
  }

  public $$getProfiles(customerId: string): Promise<$$Profile[]> {
    const http$ = this.http
      .get<GenericResponse<{ profiles: $$Profile[] }>>(env.config.feedRoot + `Customer/profiles`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.profiles));

    return firstValueFrom(http$);
  }

  public getProfiles(customerId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ profiles: Customer[] }>>(
          env.config.feedRoot + `Customer/profiles.json`,
          {
            params: { customerId },
          }
        )
        .pipe(map(({ response }) => response.profiles))
    );
  }

  public $$getCustomer(customerId: string): Promise<$$Customer> {
    const http$ = this.http
      .get<GenericResponse<$$Customer>>(env.config.feedRoot + `Customer/details`, {
        params: { customerId },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => {
          if (response.customer === null) {
            this.router.navigate(['/403']);
          }

          return response.customer;
        })
      );

    return firstValueFrom(http$);
  }

  public getCustomerDetails(customerId: string) {
    const version = this.cache.getVersion();

    const http$ = this.http
      .get<GenericResponse<Customer>>(`${env.config.feedRoot}Customer/details.json`, {
        params: { version, customerId },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => {
          if (response.customer === null) {
            this.router.navigate(['/403']);
          }

          return response.customer;
        })
      );

    return firstValueFrom(http$);
  }

  public statusType = {
    PENDING: 'Validé',
    ACTIVE: 'A valider',
    REMAINING: 'Restant',
    EXPIRED: 'Expiré',
  };

  public $$getWallet(customerId: string): Promise<$$Ticket[]> {
    const http$ = this.http
      .get<GenericResponse<{ wallet: $$Ticket[] }>>(env.config.feedRoot + `Customer/wallet`, {
        params: { customerId },
      })
      .pipe(
        map(({ response }) => {
          return response.wallet;
        })
      );

    return firstValueFrom(http$);
  }

  public getWallet(customerId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ wallet: Ticket[] }>>(env.config.feedRoot + `Customer/wallet.json`, {
          params: { customerId },
        })
        .pipe(map(({ response }) => response.wallet))
    );
  }

  public $$getContracts(customerId: string): Promise<Contract[]> {
    const http$ = this.http
      .get<GenericResponse<{ contracts: Contract[] }>>(
        `${env.config.feedRoot}Wallet/contracts.json`,
        {
          params: { customerId },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.contracts;
        })
      );

    return firstValueFrom(http$);
  }

  public getContracts(customerId: number) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ contracts: Contract[] }>>(
          `${env.config.feedRoot}Wallet/contracts.json`,
          {
            params: { customerId },
          }
        )
        .pipe(
          map(({ response }) => {
            return response.contracts;
          })
        )
    );
  }

  public $$getVouchers(customerId: string): Promise<Voucher[]> {
    const http$ = this.http
      .get<GenericResponse<{ vouchers: Voucher[] }>>(env.config.feedRoot + `Customer/vouchers`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.vouchers));

    return firstValueFrom(http$);
  }

  public $$getDocuments(customerId: string): Promise<Document[]> {
    const http$ = this.http
      .get<GenericResponse<{ documents: Document[] }>>(env.config.feedRoot + `Customer/documents`, {
        params: { customerId },
      })
      .pipe(
        map(({ response }) => {
          const documents = response.documents;

          /**
           * Essentially group every document by types:
           * ```ts
           * {
           *   '1345': [{ ... }],
           *   '1346': [{ ... }]
           * }
           * ```
           */
          const documentsGroupedByType = documents.reduce<Record<string, Document[]>>(
            (acc, doc) => {
              if (!acc[doc.type_id]) {
                acc[doc.type_id] = [];
              }

              acc[doc.type_id].push(doc);

              return acc;
            },
            {}
          );

          /**
           * Then picking only the latest one (based on their ID)
           */
          const filteredDocuments = Object.values(documentsGroupedByType).reduce<Document[]>(
            (acc, docs) => {
              const [latestDocumentForThisType] = docs.sort((a, b) => +b.id - +a.id);
              acc.push(latestDocumentForThisType);

              return acc;
            },
            []
          );

          return filteredDocuments;
        })
      );

    return firstValueFrom(http$);
  }

  public getCustomerAttributes(customerId: string): Promise<Attribute[]> {
    return firstValueFrom(
      this.http
        .get<GenericResponse<Attribute[]>>(env.config.feedRoot + `Customer/attributeValues`, {
          params: { customerId },
        })
        .pipe(map(({ response }) => response))
    );
  }

  public uploadImage(id: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('media', file);
    formData.append('id', id);

    return this.http.post<any>(env.config.feedRoot + `Customer/updateAvatar`, formData).pipe(
      map(({ response: r }) => {
        this.cache.incrementVersion();
        return r;
      })
    );
  }

  public searchCustomer(query: string): Observable<Customer[]> {
    const searchUrl = new URL(`${env.config.feedRoot}Customer/search.json`);

    const searchParams = new URLSearchParams({ ...this.globalFilters.filtersWithID, query });
    searchUrl.search = searchParams.toString();

    return of([null]).pipe(
      switchMap(() => this.http.get<GenericResponse<Customer[]>>(searchUrl.toString())),
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.customers;
      })
    );
  }

  public getSearchedCustomers(query: string): Observable<Customer[]> {
    const searchUrl = new URL(`${env.config.feedRoot}Customer/searchedCustomers.json`);

    const searchParams = new URLSearchParams({ ...this.globalFilters.filtersWithID, query });
    searchUrl.search = searchParams.toString();

    return of([null]).pipe(
      switchMap(() => this.http.get<GenericResponse<Customer[]>>(searchUrl.toString())),
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.customers;
      })
    );
  }

  public $$getGlobalAttributes(): Promise<$$GlobalAttribute[]> {
    const http$ = this.http
      .get<GenericResponse<$$GlobalAttribute[]>>(`${env.config.feedRoot}Customer/attributes`)
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => response.attributes)
      );

    return firstValueFrom(http$);
  }

  public getAttributes(): Promise<any> {
    return this.http
      .get<GenericResponse>(`${env.config.feedRoot}Customer/attributes.json`)
      .toPromise()
      .then(({ response }) => response.attributes);
  }

  public setCustomerAttribute(customerId, attributeId, value) {
    const body = {
      customerId,
      attributeId,
      value,
    };

    return this.http
      .post<SetCustomerAttributeResponse>(
        `${env.config.feedRoot}Customer/setAttributeValue.json`,
        body
      )
      .pipe(map(({ response }: any) => response))
      .toPromise();
  }

  public $$getSubscriptions(
    parentProfileId: string,
    customerId: string
  ): Promise<SubscriptionDetails[]> {
    const http$ = this.http
      .get<GenericResponse<SubscriptionDetails[]>>(
        env.config.feedRoot + `Customer/getSubscriptions`,
        {
          params: { customerId: parentProfileId },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.filter((subscription) => +subscription.recipient_user_id === +customerId);
        })
      );

    return firstValueFrom(http$);
  }

  // TODO: Type this
  public $$getMedias(customerId: string) {
    const http$ = this.http
      .get<any>(env.config.feedRoot + `Customer/medias`, {
        params: { customerId },
      })
      .pipe(map(({ response }) => response.medias));

    return firstValueFrom(http$);
  }

  public $$getComments(
    customerId: string,
    filters?: { networkId: string | number }
  ): Promise<Comment[]> {
    const http$ = this.http
      .get<GenericResponse<Comment[]>>(`${env.config.feedRoot}Customer/getCommentsAboutCustomer`, {
        params: { customerId, ...filters },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => (response.errorMessage ? [] : response)),
        map((comments) =>
          filters?.networkId !== undefined
            ? comments.filter((comment) => +comment.network_id === +filters.networkId)
            : comments
        )
      );

    return firstValueFrom(http$);
  }

  public getCustomerComments(customerId: string) {
    const value$ = new BehaviorSubject(null);

    const http$ = this.http.get(`${env.config.feedRoot}Customer/getCommentsAboutCustomer.json`, {
      params: { customerId },
    });

    const fetch = (filters: { networkId?: string } = {}) => {
      http$
        .pipe(
          tap(this.authService.checkLoggedIn),
          // If we have an error, just return an empty array..
          map(({ response }) => (response.errorMessage ? [] : response)),
          map((comments) =>
            comments && typeof filters?.networkId !== undefined
              ? comments.filter((comment) => +comment.network_id === +filters.networkId)
              : comments
          )
        )
        .toPromise()
        .then((comments) => value$.next(comments));
    };

    return { value$, fetch } as const;
  }

  public addCustomerComment(customerId: string, body: { networkId: string; text: string }) {
    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Customer/addCommentAboutCustomer.json`, {
        customerId,
        ...body,
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => response)
      );
  }

  public async editCustomerComment(
    commentId: number,
    customerId: string,
    body: { networkId: string; text: string }
  ) {
    const deleteRequest = await this.deleteCustomerComment(String(commentId));
    if (!deleteRequest) return deleteRequest;

    return firstValueFrom(this.addCustomerComment(customerId, body));
  }

  public deleteCustomerComment(commentId: string) {
    const http$ = this.http
      .delete<GenericResponse>(`${env.config.feedRoot}Customer/removeCommentAboutCustomer`, {
        params: { commentId },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => response)
      );

    return firstValueFrom(http$);
  }

  public anonymizeCustomer(customerId: string) {
    return this.http
      .delete<AnonymizeResponse>(`${env.config.feedRoot}Customer/anonymize.json`, {
        params: { customerId },
      })
      .pipe(
        map((response) => {
          return response;
        })
      )
      .toPromise();
  }

  public sendResetPasswordEmail(customerId: string) {
    return this.http
      .post<SendResetPasswordEmailResponse>(`${env.config.feedRoot}Customer/resetPassword.json`, {
        customerId,
      })
      .pipe(
        map(({ response }) => {
          return response;
        })
      )
      .toPromise();
  }

  public updateInfo(
    customerId: string,
    params: {
      firstname?: string;
      lastname?: string;
      birthday?: string;
      phone?: string;
      address?: Address;
    }
  ) {
    if (!params) return;

    return firstValueFrom(
      this.http
        .post<UpdateInfoResponse>(`${env.config.feedRoot}Customer/updateInfo.json`, {
          customerId,
          ...params,
        })
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public linkNetwork(customerId: number, networkId: number) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean; networkIds: number[] }>>(
          `${env.config.feedRoot}Customer/linkNetwork.json`,
          {
            customerId,
            networkId,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public unlinkNetwork(customerId: number, networkId: number) {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean; networkIds: number[] }>>(
          `${env.config.feedRoot}Customer/unlinkNetwork.json`,
          {
            params: { customerId, networkId },
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public disableContract(contractId: string) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ contract: Contract }>>(
          `${env.config.feedRoot}Wallet/updateContract.json`,
          {
            contractId,
            blockedAt: new Date().toISOString(),
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public reactivateContract(contractId: string) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ contract: Contract }>>(
          `${env.config.feedRoot}Wallet/updateContract.json`,
          {
            contractId,
            blockedAt: null,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public sendVerificationMail(customerId: string) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Customer/sendVerificationEmail`,
          {
            customerId: customerId,
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public releaseSessions(customerId: string) {
    return firstValueFrom(
      this.http
        .post<GenericResponse>(`${env.config.feedRoot}Customer/releaseSessions`, {
          customerId,
        })
        .pipe(
          tap(this.authService.checkLoggedIn),
          map(({ response }) => {
            return response;
          })
        )
    );
  }
}
