import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'tu-hero-icon',
  template: `
    <svg
      [title]="title"
      viewBox="0 0 24 24"
      [class]="klass"
      [style.fill]="content.outline ? 'none' : 'currentColor'"
      [style.stroke]="content.outline ? 'currentColor' : 'none'"
      [innerHTML]="content.html"
    ></svg>
  `,
})
export class HeroIconComponent {
  constructor(private sanitizer: DomSanitizer) {}

  public content: { outline: boolean; html: SafeHtml } = null;

  @Input('klass') klass = '';
  @Input('title') title = '';

  @Input('path') set path({ outline, path }: { outline: boolean; path: string }) {
    const html = this.sanitizer.bypassSecurityTrustHtml(path);

    this.content = { outline, html };
  }
}
