import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class FeedService {
  private endpoint = env.config.feedRoot;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  public get(url: string) {
    return of([null]).pipe(
      switchMap(() => this.http.get<any>(this.endpoint + url)),
      tap(this.authService.checkLoggedIn),
      tap((r) => this.checkAuthorized(r)),
      map(({ response: r }) => {
        return r;
      })
    );
  }

  public post(url: string, data: any) {
    return this.http.post<any>(this.endpoint + url, data).pipe(
      tap(this.authService.checkLoggedIn),
      tap((r) => this.checkAuthorized(r)),
      map(({ response: r }) => {
        return r;
      })
    );
  }

  public delete(url: string) {
    return this.http.delete<any>(this.endpoint + url).pipe(
      tap(this.authService.checkLoggedIn),
      tap((r) => this.checkAuthorized(r))
    );
  }

  public checkAuthorized(r) {
    if (r.errorCode) {
      this.logoutUser();
      throw new Error(r.errorCode);
    }
    if (r.errorMessage) {
      console.error(r.errorMessage);
      this.logoutUser();
    }
  }

  public logoutUser() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
