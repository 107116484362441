// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  config: {
    feedRoot: 'https://legacy-bo-ra6rh2jj5a-ew.a.run.app/feed/',
    tapRoot: 'https://staging-api-kcvqfuvlla-ew.a.run.app/',
    abtRoot: 'https://abt-ra6rh2jj5a-ew.a.run.app',
    exportRoot: 'https://api.exports.ticket.staging.airweb.fr/',
    mapApiKey: 'AIzaSyBBqcl8KrnY5PJetpLkmNT6SwxVdZwmDtQ',
    defaultCoords: '46.71109,1.7191036',
    loopedInApiBaseUrl: 'https://api.loopedin.io/v1',
    loopedInToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoiNjI2YTRjNGU1NDVhNDUwMDJhM2M1ZTI5IiwiZW1haWwiOiJwcm9kdWN0LnRlYW1AYWlyd2ViLmZyIiwibmFtZSI6IlByb2R1Y3QgVGVhbSBBaXJ3ZWIiLCJpYXQiOjE2NTgxNDQ1MzN9.hbOEUQ4LRjsGISuBmFH6mrsO6n_3MXVCCHyUqiBDtpo',
    clarityCode: 'fake-code',
    statsRoot: 'https://stats.staging.airweb.fr',
  },
};
