import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericResponse } from '../models/generic-response';
import { Network } from '../models/network';
import { Session } from '../models/session';
import { FilterService } from './filter.service';
import { ModalService } from './modal.service';

@Injectable()
export class AuthService {
  public redirectUrl: string;
  private _networks: Network[] = JSON.parse(localStorage.getItem('networks'));

  constructor(
    private http: HttpClient,
    private router: Router,
    private filterService: FilterService,
    private modal: ModalService
  ) {}

  get loggedIn(): boolean {
    return !!this.username;
  }

  set username(login: string) {
    if (login) localStorage.setItem('username', login);
    else localStorage.removeItem('username');
  }
  get username(): string {
    return localStorage.getItem('username');
  }

  set token(token: string) {
    if (token) {
      localStorage.setItem('token', token);
      //Auth cookie
      let cookieContent = `AW_AUTH_SESSION=${token}; SameSite=None; Secure;`;
      const [extension, domain] = window.location.hostname.split('.').reverse();
      if (domain && extension) {
        const DOMAIN = `.${domain}.${extension}`;
        cookieContent += `Domain=${DOMAIN}; Path:/`;
      }
      document.cookie = cookieContent;
    } else {
      localStorage.removeItem('token');
      this.clearAuthCookie();
    }
  }
  get token(): string {
    return localStorage.getItem('token');
  }

  getUserName(): string {
    return localStorage.getItem('username') || null;
  }

  set user_id(user_id: string) {
    if (user_id) localStorage.setItem('user_id', user_id);
    else localStorage.removeItem('user_id');
  }
  get user_id(): string {
    return localStorage.getItem('user_id');
  }

  set networks(networks: Network[]) {
    this._networks = networks;

    if (networks?.length) {
      const networksForLocalStorage = networks.sort((a, b) => a.name.localeCompare(b.name));
      localStorage.setItem('networks', JSON.stringify(networksForLocalStorage));
    } else {
      localStorage.removeItem('networks');
    }
  }
  get networks(): Network[] {
    try {
      if (this._networks && this._networks.length) {
        return this._networks;
      }

      return JSON.parse(localStorage.getItem('networks'));
    } catch {
      return [];
    }
  }

  set currencies(currencies: string[]) {
    if (currencies) localStorage.setItem('currencies', JSON.stringify(currencies));
    else localStorage.removeItem('currencies');
  }
  get currencies(): string[] {
    return JSON.parse(localStorage.getItem('currencies'));
  }

  private normalizePermissions(permissions: string[] | Record<number, string>) {
    if (!permissions) return [];

    return Array.isArray(permissions) ? permissions : Object.values(permissions);
  }

  set permissions(permissions: Array<string>) {
    localStorage.setItem('permissions', JSON.stringify(this.normalizePermissions(permissions)));
  }
  get permissions(): Array<string> {
    let tmp = localStorage.getItem('permissions');
    return tmp ? this.normalizePermissions(JSON.parse(tmp)) : [];
  }

  public hasPermission(permission: string) {
    return this.permissions.includes(permission);
  }

  public login(login: string, passwd: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('user', login);
    urlSearchParams.append('password', passwd);
    let body = urlSearchParams.toString();

    return this.http
      .post<any>(env.config.feedRoot + `Authenticate/login.json`, body, {
        headers,
      })
      .pipe(
        map(({ response: r }) => {
          if (r.success) {
            this.user_id = r.id;
            this.networks = r.networks;
            this.currencies = r.networks
              .map((n) => n.currency)
              .filter((c, i, a) => i === a.findIndex((v) => v === c));
            this.permissions = r.permissions;
            this.username = login;
            this.token = r.token;
          } else return false;
          return r.success;
        })
      );
  }

  public logout() {
    try {
      firstValueFrom(
        this.http.post<GenericResponse<Boolean>>(
          env.config.feedRoot + `Authenticate/logout.json`,
          {}
        )
      );
    } catch {
    } finally {
      this.clearStorage();
      this.filterService.network = null;
      this.networks = [];
      return true;
    }
  }

  public session() {
    return firstValueFrom(
      this.http
        .get<GenericResponse<Session>>(env.config.feedRoot + `Authenticate/session.json`, {})
        .pipe(map(({ response }) => response))
    );
  }

  private clearAuthCookie() {
    document.cookie = document.cookie
      .split(';')
      .map((cookie) =>
        cookie.includes('AW_AUTH_SESSION')
          ? `AW_AUTH_SESSION=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
          : cookie
      )
      .join(';');
  }

  public clearStorage() {
    localStorage.removeItem('username');
    localStorage.removeItem('networks');
    localStorage.removeItem('currencies');
    localStorage.removeItem('user_id');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
    this.clearAuthCookie();
  }

  public checkLoggedIn(response: any) {
    if (response?.errorMessage) {
      this.clearStorage();
    }
  }

  public checkLogin(): boolean {
    // Store the attempted URL for redirecting
    this.redirectUrl = location.pathname;

    // Navigate to the login page with extras
    if (!this.loggedIn) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: location.pathname },
        queryParamsHandling: 'merge',
      });
    }

    return this.loggedIn;
  }

  public getNetwork(nid: string): Network {
    return this.networks.find(({ id }) => +id === +nid);
  }

  public updateLocalNetworks(): void {
    this.http
      .get<any>(env.config.feedRoot + `Network/updateLocalNetworks.json`)
      .pipe(
        map(({ response: r }) => {
          if (r) this.networks = r.networks;
        })
      )
      .subscribe(
        (a) => console.log(a),
        (e) => console.error(e)
      );
  }

  public sessionTimeoutModal() {
    return this.modal
      .open('Votre session a expiré', 'Veuillez vous reconnecter', [
        { label: 'Se reconnecter', value: 'REDIRECT' },
      ])
      .then((response) => {
        return response;
      });
  }
}
