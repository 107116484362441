import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

const DEFAULT_HASH_PLACEHOLDER = '{{ POST_BUILD_ENTERS_HASH_HERE }}';

@Injectable()
export class CacheBustingService {
  // this will be replaced by actual hash post-build.js
  private currentHash: string = DEFAULT_HASH_PLACEHOLDER;
  private interval: NodeJS.Timeout;
  private retry = 0;

  constructor(private http: HttpClient) {}

  /**
   * Checks in every set frequency the version of frontend application
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(frequency: number = 1000 * 60 * 30): void {
    this.interval = setInterval(() => this.checkVersion(), frequency);
  }

  public clearVersionCheck(): void {
    if (typeof this.interval !== 'number') return;
    clearInterval(this.interval);
  }

  /**
   * Will do the call and check if the hash has changed or not
   */
  private checkVersion(): void {
    const timestamp = Date.now().toString();

    this.http
      .get<{ version: string; hash: string }>('/version.json', { params: { timestamp } })
      .pipe(first())
      .subscribe(
        (response) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(hash);

          // If new version, do something
          if (hashChanged) {
            const url = new URL(location.href);
            url.searchParams.append('cache', timestamp);
            location.href = url.toString();
          }

          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          this.retry++;
          console.error(err, 'Could not get version');

          if (this.retry > 10) this.clearVersionCheck();
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param {string} currentHash
   * @param {string} newHash
   * @returns {boolean}
   */
  private hasHashChanged(newHash: string): boolean {
    return this.currentHash === DEFAULT_HASH_PLACEHOLDER ? false : this.currentHash !== newHash;
  }
}
