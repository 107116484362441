import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubmissionService } from '../../submissions/services/submission.service';

@Injectable()
export class BadgeService {
  constructor(private submissionService: SubmissionService) {}

  /*private _badge: { [menu: string]: number } = {};

	set badge(total: number) { this._badge[window.location.pathname] = total; }
	get badge(): number { return this._badge[window.location.pathname]; }*/

  public getUnsubmittedDocument(): Observable<any> {
    return this.submissionService.getUnsubmitted();
  }
}
