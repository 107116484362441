<div
  class="main-ctn tw-grid tw-h-screen tw-grid-cols-[auto,1fr] tw-grid-rows-[auto,1fr] tw-overflow-hidden"
>
  <header
    class="tw-relative tw-z-20 tw-col-span-2 tw-col-start-1 tw-row-start-1 tw-bg-white tw-drop-shadow"
  >
    <div class="tw-flex tw-justify-between">
      <div class="tw-flex tw-w-56 tw-items-center">
        <a
          class="tw-relative tw-hidden tw-flex-1 tw-items-center tw-justify-center tw-px-6 md:tw-flex"
          [routerLink]="['/dashboard-next']"
        >
          <img
            class="tw-block tw-h-auto tw-w-16"
            alt="airweb logo"
            src="'../../assets/img/logo.png"
          />
        </a>

        <button
          type="button"
          class="tw-mx-6 tw-block tw-cursor-pointer tw-py-2 tw-text-xl tw-text-gray-400 tw-no-underline hover:tw-text-gray-700 md:tw-ml-auto md:tw-mr-0"
          (click)="isSidebarToggled = !isSidebarToggled"
        >
          <span class="tw-sr-only">Menu</span>
          <tu-hero-icon [path]="heroicons.outline.menu" klass="tw-h-6"></tu-hero-icon>
        </button>
      </div>

      <ul class="tw-m-0 tw-flex tw-items-center tw-p-2">
        <li class="tw-hidden md:tw-inline"></li>
        <li *ngIf="!router.isActive('/customers/search', true)">
          <button
            (click)="openSearchModal()"
            class="tw-relative tw-block tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-700 focus:tw-text-gray-700 focus:tw-outline-none"
          >
            <tu-hero-icon
              [path]="heroicons.outline.search"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
            <span class="tw-hidden md:tw-inline">
              &nbsp;{{ 'pages.header.search_client' | translate }}
            </span>
          </button>
        </li>
        <li class="tw-relative">
          <button
            (click)="openedDropdowns.documents = !openedDropdowns.documents"
            class="tw-flex tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-700 focus:tw-text-gray-700 focus:tw-outline-none"
          >
            <tu-hero-icon
              [path]="heroicons.outline.document"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
            <span class="tw-hidden md:tw-inline">
              &nbsp;{{ 'pages.header.documents' | translate }}
            </span>
            <tu-hero-icon
              [path]="heroicons.solid.chevronDown"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
          </button>
          <!--Click away trigger-->
          <div
            *ngIf="openedDropdowns.documents"
            (click)="openedDropdowns.documents = false"
            class="tw-fixed tw-inset-0 tw-z-20 tw-h-screen tw-w-screen"
          ></div>
          <div
            *ngIf="openedDropdowns.documents"
            (click)="openedDropdowns.documents = false"
            class="tw-absolute tw-right-0 tw-z-30 tw-mt-2 tw-rounded-md tw-bg-white tw-py-2 tw-shadow-xl"
          >
            <a
              (click)="openedDropdowns.documents = false"
              class="tw-block tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-400 tw-no-underline hover:tw-text-gray-700"
              routerLinkActive="active"
              [routerLink]="['/documents']"
            >
              <tu-hero-icon
                [path]="heroicons.outline.documentText"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              &nbsp;
              <span>{{ 'pages.header.documents' | translate }}</span>
            </a>
            <a
              (click)="openedDropdowns.documents = false"
              class="tw-block tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-400 tw-no-underline hover:tw-text-gray-700"
              routerLinkActive="active"
              [routerLink]="['/graphic-documents']"
            >
              <tu-hero-icon
                [path]="heroicons.outline.photograph"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              &nbsp;
              <span>{{ 'pages.header.graphic_documents' | translate }}</span>
            </a>
          </div>
        </li>
        <li class="tw-relative">
          <button
            (click)="openedDropdowns.account = !openedDropdowns.account"
            class="tw-flex tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-700 focus:tw-text-gray-700 focus:tw-outline-none"
          >
            <tu-hero-icon
              [path]="heroicons.outline.user"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
            <span class="tw-hidden md:tw-inline">&nbsp;{{ userLogin }}</span>
            <tu-hero-icon
              [path]="heroicons.solid.chevronDown"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
          </button>
          <!--Click away trigger-->
          <div
            *ngIf="openedDropdowns.account"
            (click)="openedDropdowns.account = false"
            class="tw-fixed tw-inset-0 tw-z-20 tw-h-screen tw-w-screen"
          ></div>
          <div
            *ngIf="openedDropdowns.account"
            class="tw-absolute tw-right-0 tw-z-30 tw-mt-2 tw-rounded-md tw-bg-white tw-py-2 tw-shadow-xl"
          >
            <a
              (click)="openedDropdowns.account = false"
              class="tw-block tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-400 tw-no-underline hover:tw-text-gray-700"
              [routerLink]="['/account-management']"
            >
              <tu-hero-icon
                [path]="heroicons.outline.user"
                klass="tw-h-5  tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              &nbsp;{{ 'pages.account_management.my_account' | translate }}
            </a>
            <a
              (click)="openedDropdowns.account = false"
              class="tw-block tw-whitespace-nowrap tw-px-4 tw-py-2 tw-text-sm tw-text-gray-400 tw-no-underline hover:tw-text-gray-700"
              [routerLink]="['/login', { action: 'logout' }]"
            >
              <tu-hero-icon
                [path]="heroicons.outline.logout"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              &nbsp;{{ 'pages.auth.logout' | translate }}
            </a>
          </div>
        </li>
        <li class="tw-relative">
          <button
            (click)="openedDropdowns.language = !openedDropdowns.language"
            class="tw-flex tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-700 focus:tw-text-gray-700 focus:tw-outline-none"
          >
            <span
              class="tw-h-5 tw-inline-block tw-mx-auto flag-icon flag-icon-{{ selectedLang.flag }}"
            ></span>
            <tu-hero-icon
              [path]="heroicons.solid.chevronDown"
              klass="tw-h-5 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
          </button>
          <!--Click away trigger-->
          <div
            *ngIf="openedDropdowns.language"
            (click)="openedDropdowns.language = false"
            class="tw-fixed tw-inset-0 tw-z-20 tw-h-screen tw-w-screen"
          ></div>
          <div
            *ngIf="openedDropdowns.language"
            class="tw-absolute tw-right-0 tw-z-30 tw-mt-2 tw-rounded-md tw-bg-white tw-py-2 tw-shadow-xl"
          >
            <button
              *ngFor="let lang of langsTab"
              type="button"
              (click)="openedDropdowns.language = false; setDefaultLang(lang.locale)"
              class="tw-block tw-px-4 tw-py-2 tw-text-center tw-text-sm tw-text-gray-400 hover:tw-text-gray-700"
            >
              <span class="flag-icon flag-icon-{{ lang.flag }} d-inline-block mr-0"></span>
            </button>
          </div>
        </li>
        <li class="tw-relative">
          <a
            [routerLink]="['/changelog']"
            class="tw-flex tw-items-center tw-justify-center"
            [ngbTooltip]="'pages.changelog.title' | translate"
          >
            <span class="tw-sr-only">{{ 'pages.changelog.title' | translate }}</span>
            <tu-hero-icon
              [path]="heroicons.outline.bell"
              klass="tw-h-6 tw-text-yellow-500"
            ></tu-hero-icon>
          </a>
        </li>
      </ul>
    </div>
  </header>

  <!--Click away trigger-->
  <div
    *ngIf="isSidebarToggled && isMobile"
    (click)="isSidebarToggled = false"
    class="tw-z-20 tw-col-span-2 tw-col-start-1 tw-row-span-2 tw-row-start-1 tw-h-full tw-w-full tw-bg-black tw-bg-opacity-30"
  ></div>

  <nav
    class="sidebar-custom tw-relative tw-z-30 tw-col-start-1 tw-row-start-2 tw-h-full tw-overflow-hidden"
    [style]="sidebarStyle"
  >
    <ul
      class="tw-m-0 tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll"
      [class.tw-w-56]="isSidebarToggled"
    >
      <li [hasAllPermission]="['ACCESS_DASHBOARD']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/dashboard-next']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.chartBar"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.dash' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_ORDERS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/orders']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.shoppingBag"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.sales' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_ORDERS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/subscriptions']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.shoppingBag"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.subscriptions' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_SCHOOL_ORDERS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/school']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.shoppingBag"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.school_sales' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_CUSTOMERS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/customers']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.users"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.clients' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li
        [hasOnePermission]="[
          'ACCESS_VALIDATIONS',
          'ACCESS_TICKETS',
          'ACCESS_GEOLOCATIONS',
          'ACCESS_CONTROLS',
          'ACCESS_COLORS'
        ]"
        [class]="openedDropdowns.usage ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.usage = !openedDropdowns.usage"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.collection"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.usage_menu' | translate }}&nbsp;
            </span>
          </span>

          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto ' +
              (openedDropdowns.usage && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>

        <div [class]="openedDropdowns.usage ? 'tw-block' : 'tw-hidden'">
          <a
            [hasAllPermission]="['ACCESS_VALIDATIONS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/validations']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.badgeCheck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.validations' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_TICKETS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/ticket']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.ticket"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.titles' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_GEOLOCATIONS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/geolocation']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.globe"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.geolocations' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_CONTROLS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/controls']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.clipboardCheck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.controls' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_COLORS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/colors']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.colorSwatch"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.colors' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="od"
            [hasAllPermission]="['ACCESS_TRIPS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/booking']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.calendar"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.booking' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li
        [hasOnePermission]="[
          'EDIT_TERMS',
          'EDIT_LEGAL_NOTICE',
          'EDIT_PRIVACY_POLICY',
          'EDIT_CONTACT'
        ]"
        [class]="openedDropdowns.legal ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.legal = !openedDropdowns.legal"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <tu-hero-icon
            [path]="heroicons.outline.bookOpen"
            klass="tw-h-5 tw-inline-block tw-mx-auto"
          ></tu-hero-icon>

          <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
            &nbsp;{{ 'pages.list_menu.legal_information_menu' | translate }}&nbsp;
          </span>

          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto  ' +
              (openedDropdowns.legal && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>

        <div [class]="openedDropdowns.legal ? 'tw-block' : 'tw-hidden'">
          <a
            [hasAllPermission]="['EDIT_TERMS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/salesterms']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.bookOpen"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.t_amp_cs' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['EDIT_LEGAL_NOTICE']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/legal-notice']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.bookOpen"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.legal_information' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['EDIT_PRIVACY_POLICY']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/privacy-policy']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.shieldExclamation"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.privacy_policy' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['EDIT_CONTACT']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/contact']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.phone"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.contact' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>
      <li
        [hasOnePermission]="[
          'MANAGE_PAYMENT',
          'MANAGE_PAYMENT_EMAILS',
          'ACCESS_FLEET',
          'EDIT_GRAPHICS',
          'EDIT_TICKET_BACKGROUND',
          'VIEW_USERS'
        ]"
        [class]="openedDropdowns.settings ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.settings = !openedDropdowns.settings"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.cog"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.settings_menu' | translate }}&nbsp;
            </span>
          </span>
          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto  ' +
              (openedDropdowns.settings && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>
        <div [class]="openedDropdowns.settings ? 'tw-block' : 'tw-hidden'">
          <a
            [hasOnePermission]="['MANAGE_PAYMENT', 'MANAGE_PAYMENT_EMAILS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/payment']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.cash"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.payment' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_FLEET']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/fleet']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.qrcode"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.park' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="od"
            [hasAllPermission]="['ACCESS_VEHICLE']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/vehicle']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.truck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.vehicles' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_TRIPS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/trips']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.switchHorizontal"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.trips' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['ACCESS_PROVIDERS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/providers']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.link"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.providers' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasOnePermission]="['EDIT_GRAPHICS', 'EDIT_TICKET_BACKGROUND']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/graphics']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.photograph"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.graphical' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['VIEW_USERS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/users']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.photograph"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.users' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li
        [hasOnePermission]="['CREATE_NETWORK', 'MANAGE_NETWORKS', 'MANAGE_GTFS']"
        [class]="openedDropdowns.networks ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.networks = !openedDropdowns.networks"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.share"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.networks_menu' | translate }}&nbsp;
            </span>
          </span>
          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto  ' +
              (openedDropdowns.networks && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>

        <div [class]="openedDropdowns.networks ? 'tw-block' : 'tw-hidden'">
          <a
            [hasAllPermission]="['CREATE_NETWORK']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/networks/create']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.locationMarker"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.create_network' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['MANAGE_NETWORKS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/networks']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.informationCircle"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.networks' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['MANAGE_GTFS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/gtfs']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.map"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.gtfs_import' | translate }}
              </span>
            </span>
          </a>

          <a
            [hasAllPermission]="['MANAGE_CLIENTS']"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/clients']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.deviceMobile"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.regions_applications' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li
        [hasOnePermission]="['MANAGE_STORE']"
        [class]="openedDropdowns.shop ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.shop = !openedDropdowns.shop"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.shoppingCart"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.shop_menu' | translate }}&nbsp;
            </span>
          </span>
          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto  ' +
              (openedDropdowns.shop && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>
        <div [class]="openedDropdowns.shop ? 'tw-block' : 'tw-hidden'">
          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/categories']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.tag"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.categories' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/blueprints']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.library"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;Type de titre v2
                <br />
                &nbsp;Blueprint
              </span>
            </span>
          </a>

          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/products-type']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.annotation"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;Type de titre v1
              </span>
            </span>
          </a>

          <a
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/products']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.ticket"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.products' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/ordering']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.sortAscending"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.scheduling' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/shop/family']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.userGroup"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.family' | translate }}
              </span>
            </span>
          </a>

          <a
            *ngIf="!hasShopRestricted"
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/fields']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.flag"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.fields' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li [hasAllPermission]="['ACCESS_SUBMISSIONS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/submissions']"
          class="relative sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-relative tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.documentDuplicate"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.submission' | translate }}&nbsp;
            </span>
            <span
              class="tag tag-pill tag-danger"
              [ngClass]="{
                'tw-absolute tw-top-0 tw-right-0 tw-z-50 tw-h-4 tw-translate-x-1/2':
                  !isSidebarToggled
              }"
              *ngIf="badgeSubmission"
            >
              <span [class.tw-sr-only]="!isSidebarToggled">
                {{ badgeSubmission < 10 ? badgeSubmission : '+10' }}
              </span>
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_REPORTS']" *ngIf="access_reports" class="nav-item">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/reports']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.documentDuplicate"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.reports' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li [hasAllPermission]="['ACCESS_AUDIT_TRAILS']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/logs']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.newspaper"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>
            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.logs' | translate }}
            </span>
          </span>
        </a>
      </li>

      <li
        [hasAllPermission]="['TAPNPROTECT']"
        [class]="openedDropdowns.tapAndProtect ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.tapAndProtect = !openedDropdowns.tapAndProtect"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.creditCard"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;Tap & Protect&nbsp;
            </span>
          </span>
          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto  ' +
              (openedDropdowns.tapAndProtect && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>

        <div [class]="openedDropdowns.tapAndProtect ? 'tw-block' : 'tw-hidden'">
          <a
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
            routerLinkActive="active"
            [routerLink]="['/tap-and-protect/imports']"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.clipboardCheck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.tap_csv_imports' | translate }}
              </span>
            </span>
          </a>

          <a
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
            routerLinkActive="active"
            [routerLink]="['/tap-and-protect/exports']"
            [hasAllPermission]="['TAPNPROTECT_EXPORTS']"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.clipboardCheck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.tap_csv_exports' | translate }}
              </span>
            </span>
          </a>

          <a
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/tap-and-protect/list']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.clipboardList"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>
              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.tap_transactions_details' | translate }}
              </span>
            </span>
          </a>

          <a
            routerLinkActive="sidebar-tab-focused"
            [routerLink]="['/tap-and-protect/dashboard']"
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.chartBar"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.list_menu.tap_dashboard' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li
        [hasOnePermission]="['ACCESS_MACHINES', 'ACCESS_MACHINES_VALIDATIONS']"
        [class]="openedDropdowns.validators ? 'sidebar-dropdown-open' : ''"
      >
        <button
          (click)="openedDropdowns.validators = !openedDropdowns.validators"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.clipboardCheck"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;{{ 'pages.list_menu.validators' | translate }}&nbsp;
            </span>
          </span>
          <tu-hero-icon
            [path]="heroicons.solid.chevronRight"
            [klass]="
              (isSidebarToggled ? 'tw-inline-block ' : 'tw-hidden ') +
              'tw-h-5 tw-transition tw-duration-300 tw-ease-in-out tw-mx-auto ' +
              (openedDropdowns.validators && 'tw-rotate-90')
            "
          ></tu-hero-icon>
        </button>

        <div
          [hasAllPermission]="['ACCESS_MACHINES']"
          [class]="openedDropdowns.validators ? 'tw-block' : 'tw-hidden'"
        >
          <a
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
            routerLinkActive="active"
            [routerLink]="['/validators/machines']"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.chip"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.validators.machines' | translate }}
              </span>
            </span>
          </a>
        </div>

        <div
          [hasAllPermission]="['ACCESS_MACHINES_VALIDATIONS']"
          [class]="openedDropdowns.validators ? 'tw-block' : 'tw-hidden'"
        >
          <a
            class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
            routerLinkActive="active"
            [routerLink]="['/validators/validations']"
          >
            <span class="tw-flex tw-items-center">
              <tu-hero-icon
                [path]="heroicons.outline.badgeCheck"
                klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
              ></tu-hero-icon>

              <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
                &nbsp;{{ 'pages.validators_validations.validator_events' | translate }}
              </span>
            </span>
          </a>
        </div>
      </li>

      <li [hasOnePermission]="['VIEW_PROVISIONING', 'MANAGE_PROVISIONING']">
        <a
          routerLinkActive="sidebar-tab-focused"
          [routerLink]="['/provisioning']"
          class="sidebar-tab tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3 tw-py-3 tw-no-underline focus:tw-outline-none"
        >
          <span class="tw-flex tw-items-center">
            <tu-hero-icon
              [path]="heroicons.outline.collection"
              klass="md:tw-h-5 tw-h-6 tw-inline-block tw-mx-auto"
            ></tu-hero-icon>

            <span [class.sr-only]="!isSidebarToggled" class="tw-mx-4 tw-text-left tw-font-medium">
              &nbsp;
              {{ 'pages.provisioning.provisioning' | translate }}
            </span>
          </span>
        </a>
      </li>
    </ul>
  </nav>

  <main class="tw-relative tw-z-10 tw-col-start-2 tw-row-start-2 tw-overflow-hidden">
    <div class="tw-mb-4 tw-bg-white tw-p-3 tw-pl-6 tw-drop-shadow">
      <tu-breadcrumb></tu-breadcrumb>
    </div>

    <div class="container-fluid tw-h-full tw-overflow-auto tw-pb-36">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer
    class="tw-col-start-2 tw-row-start-2 tw-self-end tw-justify-self-end tw-p-4 tw-text-right"
  >
    <span>v{{ appVersion }}</span>
    <span>
      AirwebPass &copy; 2016 - {{ year }}
      <a href="http://airweb.fr" target="_blank" rel="noopener noreferrer">Airweb</a>
    </span>
  </footer>

  <tu-modal></tu-modal>
</div>
