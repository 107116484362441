import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDocument } from '@app/modules/orders/models/document';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { CacheManager } from '@app/utils/cache-manager';
import { environment as env } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BillingInfo, Domain, NetworkInfo, NetworkProvider } from '../models/network-info';

@Injectable()
export class NetworkService {
  private cache = new CacheManager('network');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notification: NotificationsService,
    private readonly translate: TranslateService
  ) {}

  public getNetworks({ withId = false }): Observable<NetworkInfo[]> {
    let version = this.cache.getVersion();
    const file = withId ? `Network/getNetworks.json?withId=true&v=` : `Network/getNetworks.json?v=`;

    return this.http.get<GenericResponse<NetworkInfo[]>>(env.config.feedRoot + file + version).pipe(
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.networks;
      })
    );
  }

  public getNetworksDetails(id: number): Observable<NetworkInfo> {
    let version = this.cache.getVersion();
    return this.http
      .get<GenericResponse<NetworkInfo>>(env.config.feedRoot + 'Network/getNetworks.json', {
        params: { id, v: version },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => {
          return response.networks[0];
        })
      );
  }

  public updateNetwork(
    networkInfo: NetworkInfo,
    id: number,
    defaultLocale: string,
    locales: string,
    translations: any
  ) {
    return this.http
      .post<GenericResponse>(env.config.feedRoot + `Network/editNetworks.json`, {
        networkInfo,
        id,
        defaultLocale,
        locales,
        translations,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            if (response.errorCode !== null) throw new Error(response.errorCode);
          } else {
            this.cache.incrementVersion();
            return response;
          }
        })
      );
  }

  public createNetwork(name: string): Observable<{ id: number }> {
    return this.http
      .post<GenericResponse<{ id: number }>>(
        env.config.feedRoot + `Network/createNetwork.json`,
        name
      )
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            if (response.errorCode !== null) throw new Error(response.errorCode);
          } else {
            this.cache.incrementVersion();
            return response;
          }
        })
      );
  }

  public getDocuments(networkId: number): Observable<IDocument[]> {
    return this.http
      .get(env.config.feedRoot + `Network/getDocuments.json`, {
        params: {
          networkId,
        },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => {
          return response.documents;
        })
      );
  }

  public getMails(): Observable<any> {
    return this.http.get<GenericResponse>(env.config.feedRoot + `Network/getMails.json`).pipe(
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.emails;
      })
    );
  }

  public getTags(): Observable<any> {
    return this.http.get<GenericResponse>(env.config.feedRoot + `Network/getTags.json`).pipe(
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.tags;
      })
    );
  }

  public setNetworkMailTags(datas: any): Observable<any> {
    return this.http.post<any>(env.config.feedRoot + `Network/setNetworkMailTags.json`, datas).pipe(
      tap(this.authService.checkLoggedIn),
      map(({ response }) => {
        return response.success;
      })
    );
  }

  public editNetworkClients(networkId: string, isTixiPass: boolean, clients: number[]) {
    const body = { networkId, isTixiPass, clients };

    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Network/editNetworkClients.json`, body)
      .pipe(map(({ response }) => response));
  }

  public getBillingInfo(networkId: string) {
    return this.http
      .get<GenericResponse<NetworkInfo>>(env.config.feedRoot + 'Network/getBillingInfo.json', {
        params: { networkId },
      })
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response }) => response)
      );
  }

  public addBillingInfo(networkId: string, info: Partial<BillingInfo>) {
    const body = { networkId: +networkId, ...info };
    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Network/addBillingInfo.json`, null, {
        params: body,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_added_notification')
            );
            return response;
          }
        })
      );
  }

  public updateBillingInfo(info: BillingInfo) {
    return this.http
      .put<GenericResponse>(`${env.config.feedRoot}Network/updateBillingInfo.json`, null, {
        params: { ...info },
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_updated_notification')
            );
            return response;
          }
        })
      );
  }

  public deleteBillingInfo(id: number) {
    //id = billing field id
    return this.http
      .delete<GenericResponse>(`${env.config.feedRoot}Network/removeBillingInfo.json`, {
        params: { id },
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_deleted_notification')
            );
            return response;
          }
        })
      );
  }

  public getDomains(networkId: number) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ domains: Domain[] }>>(
          `${env.config.feedRoot}Network/getDomains.json`,
          {
            params: { networkId },
          }
        )
        .pipe(
          map(({ response }) => {
            return response.domains;
          })
        )
    );
  }

  public addDomain(domainInfo) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/addDomain.json`,
          domainInfo
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public editDomain(domainInfo) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/editDomain.json`,
          domainInfo
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public deleteDomain(networkId: number, domain: string) {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/deleteDomain.json`,
          {
            params: { networkId, domain },
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public getProviders(networkId, onlyTicketing = false) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<NetworkProvider[]>>(`${env.config.feedRoot}Network/providers.json`, {
          params: { networkId, onlyTicketing },
        })
        .pipe(
          map(({ response }) => {
            if (response.errorMessage) {
              throw new Error(response.errorCode);
            } else {
              return response;
            }
          })
        )
    );
  }

  public updateNetworkProviders(networkId: number, providerIds: number[]) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/updateProviders.json`,
          {
            networkId,
            providerIds,
          }
        )
        .pipe(
          map(({ response }) => {
            if (response.errorMessage) {
              throw new Error(response.errorCode);
            } else {
              return response;
            }
          })
        )
    );
  }
}
