import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { environment as env } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class TranslationService {
  private _translations: any;
  set translations(langs: any) {
    this._translations = langs;
  }
  get translations(): any {
    return this._translations;
  }

  private currentLang: string | LangChangeEvent = this.translateService.getDefaultLang();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  public getTranslations(): Observable<any> {
    this.translateService.onLangChange.subscribe((lang) => (this.currentLang = lang));
    return this.http
      .get<any>(env.config.feedRoot + `Translations/getLanguages.json?lang=${this.currentLang}`)
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response: r }) => {
          this.translations = r;
          return r;
        })
      );
  }
}
