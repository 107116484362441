import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Customer } from '@app/modules/customers/models/customer';
import { SearchModalComponent } from '@app/modules/shared/components/search-modal/search-modal.component';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { BadgeService } from '@app/modules/shared/services/badge.service';
import { ColorService } from '@app/modules/shared/services/color.service';
import { heroicons } from '@assets/icons/heroicons';
import { environment as env } from '@env/environment';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import pkg from '../../../package.json';

const MOBILE_MEDIA_QUERY = '(max-width: 767px)';

@Component({
  selector: 'tu-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public config: NgbTypeaheadConfig,
    public router: Router,
    public translate: TranslateService,
    public badgeService: BadgeService,
    public colorService: ColorService,
    public modal: MatDialog,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    const cachedLang = localStorage.getItem('userPreferedLang');
    translate.addLangs(this.langsTab.map((_) => _.locale));
    const browserLang = cachedLang || translate.getBrowserLang();
    const locale = browserLang.match(/en|fr/) ? browserLang : 'en';
    this.selectedLang = this.langsTab.find((l) => l.locale === locale);
    translate.setDefaultLang(locale);
    translate.use(locale);
  }

  public keys = Object.keys;

  public get sidebarStyle() {
    const style = this.colorService.sidebarColor;
    const background = `--sidebar-bg : ${style.primary}; `;
    const text = `--sidebar-text : ${style.text}; `;
    const selected = `--sidebar-bg-active : ${style.selected}; `;
    const hover = `--sidebar-bg-hover : ${style.hover}; `;
    const dropdown = `--sidebar-bg-dropdown : ${style.dropdown}; `;

    return background + text + selected + hover + dropdown;
  }

  public get hasShopRestricted() {
    //Maybe edit permission name EDIT_TEXT_PRODUCT to EDIT_RESTRICTIVE_TEXT_PRODUCT
    //Check if EDIT_ITEM_PRODUCT_RESTRICTED is not the same as EDIT_ITEMS_PRODUCT_RESTRICTIVE
    const restricted_permissions = [
      'EDIT_ITEM_PRODUCT_RESTRICTED',
      'EDIT_ITEM_PRODUCT_RESTRICTED_EDITOR',
      'EDIT_ITEM_PRODUCT_RESTRICTED_MODIFICATOR',
      'EDIT_ITEM_PRODUCT_RESTRICTED_CREATOR',
    ];
    for (const permission of restricted_permissions) {
      if (this.authService.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  public openedDropdowns: Record<string, boolean> = {};
  public isSidebarToggled = true;
  public isMobile = false;
  public heroicons = heroicons;
  public appVersion = pkg.version + (env.production ? '' : '-dev');
  public closeResult: string;
  public userLogin: string;
  public parent: AppComponent;
  public width = document.documentElement.clientWidth;
  public customers: Customer[];
  public langsTab = [
    // HACK: Hardcoded flags
    { locale: 'fr', label: 'FR', flag: 'fr' },
    { locale: 'en', label: 'EN', flag: 'gb' },
    { locale: 'es', label: 'ES', flag: 'es' },
    { locale: 'it', label: 'IT', flag: 'it' },
  ];
  public selectedLang = null;
  public flags = null;

  public year = new Date().getFullYear();

  public od: boolean;

  public access_reports = false;
  public badgeSubmission = null;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.badgeService.getUnsubmittedDocument().subscribe((e) => (this.badgeSubmission = e));
    this.userLogin = this.authService.getUserName();
    this.isMobile = window.matchMedia(MOBILE_MEDIA_QUERY).matches;
    this.isSidebarToggled = !this.isMobile;

    window.onresize = () => {
      this.isMobile = window.matchMedia(MOBILE_MEDIA_QUERY).matches;
    };

    this.authService.networks.forEach((n) => {
      if (n.payment_company === 'LEMONWAY') this.access_reports = true;
    });

    if (this.authService.networks.length === 1) {
      this.od = this.authService.networks[0].od;
    } else this.od = true;

    // We listen to route change and close the sidebar
    // if we navigate to a new page
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (!this.isMobile) return;
      this.isSidebarToggled = false;
    });
  }
  public openSearchModal() {
    // I don't even...
    // https://stackoverflow.com/a/58866045
    this.changeDetector.detach();

    const modalRef = this.modal.open(SearchModalComponent, {
      width: '600px',
      position: { top: '10vh' },
      maxWidth: '100vw',
      maxHeight: '80vh',
    });

    // I don't even...
    modalRef.afterClosed().subscribe(() => {
      this.changeDetector.reattach();
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public setDefaultLang(lang) {
    this.translate.use(lang);
    this.selectedLang = this.langsTab.find((l) => l.locale === lang);
    localStorage.setItem('userPreferedLang', this.selectedLang.locale);
  }
}
