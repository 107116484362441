import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class LinesService {
  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  public getLines(): Observable<any[]> {
    return this.http.get<any>(env.config.feedRoot + 'Lines/getLines.json').pipe(
      tap(this.authService.checkLoggedIn),
      map(({ response: r }) => {
        return r.lines as any[];
      })
    );
  }

  public getRoutes(nid: string, agency_key: string): Observable<any[]> {
    return this.http
      .get<any>(
        env.config.feedRoot + `Lines/getRoutes.json?agency_key=${agency_key}&network_id=${nid}`
      )
      .pipe(
        tap(this.authService.checkLoggedIn),
        map(({ response: r }) => {
          return r.routes as any[];
        })
      );
  }
}
