import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app';
import { environment } from './environments/environment';
import './polyfills.ts';

if (environment.production) {
  enableProdMode();
}

// @ts-ignore
tarteaucitron.user.clarity = environment.config.clarityCode;
// @ts-ignore
(tarteaucitron.job = tarteaucitron.job || []).push('clarity');

platformBrowserDynamic().bootstrapModule(AppModule);
