type VersionNumbers = { [serviceName: string]: number };

export class CacheManager {
  private lsKey = 'cacheVersions';
  private serviceName;

  private set versions(v: VersionNumbers) {
    if (v) localStorage.setItem(this.lsKey, JSON.stringify(v));
    else localStorage.removeItem(this.lsKey);
  }
  private get versions(): VersionNumbers {
    return JSON.parse(localStorage.getItem(this.lsKey));
  }

  constructor(serviceName) {
    this.serviceName = serviceName;
  }

  public incrementVersion() {
    let v = this.getVersion() + 1;
    this.versions = { ...this.versions, [this.serviceName]: v };
  }

  public getVersion(): number {
    return this.versions && this.versions[this.serviceName] ? this.versions[this.serviceName] : 0;
  }
}
